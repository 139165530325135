import React from 'react';
import { connect } from 'react-redux';
import {getTransactions, getPathName} from '../redux/actions/getData';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from 'react-router-dom';
import { prefixDefault, denomination } from '../data/API';
import moment from 'moment';

class Transaction extends React.Component {
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData, grid) => {
    if((this.props.fullData.latestTransaction).length > 0) {
      var totalData = 0;
      var txs = [];
      var colClass1 = "col-md-1 col-5"
      var colClass2 = "col-md-2 col-6"
      var colClass3 = "col-md-2 col-6"
      if(grid){
        colClass1 = "col-md-3 col-5"
        colClass2 = "col-md-3 col-6"
        colClass3 = "col-md-6 col-6"
      }
      (this.props.fullData.latestTransaction).forEach(tx => {
        if(totalData < numData) {
          txs.push(
            <Link to={`/tx/${tx.txHash}`} onClick={() => this.changePath("/tx")}>
              <div style={{padding: "6px"}}>
                <div className="row">
                  <div className="col-md-3 col-7">
                    {
                      tx.status === true ?
                        <p className="text-hash" style={{"textTransform": "capitalize"}}><i className="icon bi-check-circle-fill" style={{"color": "green"}}></i> {tx.txType}</p>
                      :
                      <p className="text-hash" style={{"textTransform": "capitalize"}}><Tooltip interactive title={tx.status} placement="top-start"><i className="icon bi-x-circle-fill" style={{"color": "red"}}></i></Tooltip> {tx.txType}</p>
                    }
                  </div>
                  <div className={colClass1}>
                    {
                      !this.props.block ?
                        <p><Link to={`/block/${tx.txHeight}`} onClick={() => this.changePath("/block")}><b>{parseInt(tx.txHeight).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></Link></p>
                      : <p>{moment(tx.txTime).format("D/MM/YY HH:mm:ss")}</p>
                    }
                  </div>
                  <div className={colClass2}>
                    <p><b>
                      {
                        parseInt(tx.txAmount) > 0 ?
                          (parseInt(tx.txAmount)/10**6).toLocaleString(undefined, {maximumFractionDigits: 3})
                        : tx.txAdditional ?
                          parseInt(tx.txAmount) > 0 ?
                            (parseInt(tx.txAdditional.amount)/10**6).toLocaleString(undefined, {maximumFractionDigits: 3})
                          : (0/10**6).toLocaleString(undefined, {maximumFractionDigits: 3})
                        : (0/10**6).toLocaleString(undefined, {maximumFractionDigits: 3})
                      }
                    </b>
                    {
                      tx.txAdditional ?
                        tx.txAdditional.denom ?
                          ` ${tx.txAdditional.denom}`
                        : ` ${denomination}`
                      : ` ${denomination}`
                    }
                    </p>
                  </div>
                  <div className={colClass2}>
                    {
                      this.props.gas ?
                        <p className="text-hash">{parseInt(tx.txGasUsed).toLocaleString(undefined, {maximumFractionDigits: 6})} / {parseInt(tx.txGasWanted).toLocaleString(undefined, {maximumFractionDigits: 6})}</p>
                      :
                        <p>Fee <b>{(parseInt(tx.txFee)/10**6).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                    }
                  </div>
                  <div className={colClass3}>
                    {
                      !this.props.sender ?
                        tx.txSender ?
                          (tx.txSender).includes('valoper') ?
                            <p className="text-hash">From <Link to={`/validator/${tx.txSender}`} onClick={() => this.changePath("/validator")}><b>{tx.txSender}</b></Link></p>
                          : <p className="text-hash">From <Link to={`/address/${tx.txSender}`} onClick={() => this.changePath("/account")}><b>{tx.txSender}</b></Link></p>
                        : null
                      :
                        tx.txReceiver ?
                          tx.txReceiver && (tx.txReceiver).includes(prefixDefault) ?
                            (tx.txReceiver).includes('valoper') ?
                              <p className="text-hash">To <Link to={`/validator/${tx.txReceiver}`} onClick={() => this.changePath("/validator")}><b>{tx.txReceiver}</b></Link></p>
                            : <p className="text-hash">To <Link to={`/address/${tx.txReceiver}`} onClick={() => this.changePath("/account")}><b>{tx.txReceiver}</b></Link></p>
                          : <p className="text-hash">To <b>{tx.txReceiver}</b></p>
                        :
                          tx.txReceiver && (tx.txReceiver).includes(prefixDefault) ?
                            (tx.txReceiver).includes('valoper') ?
                              <p className="text-hash">To <Link to={`/validator/${tx.txReceiver}`} onClick={() => this.changePath("/validator")}><b>{tx.txReceiver}</b></Link></p>
                            : <p className="text-hash">To <Link to={`/address/${tx.txReceiver}`} onClick={() => this.changePath("/account")}><b>{tx.txReceiver}</b></Link></p>
                          :
                            tx.txAdditional.to ?
                              <p className="text-hash">To <b>{tx.txAdditional.to}</b></p>
                            : null
                    }
                  </div>
                  <div className={colClass3}>
                    <p className="text-hash"><b>{tx.txHash}</b></p>
                  </div>
                </div>
              </div>
              <hr style={{marginTop: "0px"}} />
            </Link>
          )
        }
        totalData++;
      })
    }
    return txs;
  }
  render() {
    return (
      <div>
        {
          (this.props.fullData.latestTransaction).length > 0 ?
            <div>
              {this.loopData(this.props.total, this.props.grid)}
            </div>
          :
          <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    fullData: state.user,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getTransactions,
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);