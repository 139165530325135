import React from 'react';
import '../assets/css/styles.css';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { connect } from 'react-redux';
import { getBlocks, getValidators, getSummary, getTransactions, getPrice, resetStatusSummary, resetStatusBlock, resetStatusTransaction, resetStatusValidator, resetStatusPrice } from '../redux/actions/getData';

class Summary extends React.Component {
  componentDidMount() {
    this.props.getValidators();
    this.props.getBlocks();
    this.props.getSummary();
    this.props.getTransactions();
    this.props.getPrice();
    setInterval(() => {
      if(this.props.fullData.statusCodeBlock) {
        this.props.resetStatusBlock();
        this.props.getBlocks();
      }
      if(this.props.fullData.statusCodeTransaction) {
        this.props.resetStatusTransaction();
        this.props.getTransactions();
      }
      if(this.props.fullData.statusCodeValidator) {
        this.props.resetStatusValidator();
        this.props.getValidators();
      }
    }, 6000);
    setInterval(() => {
      if(this.props.fullData.statusCodeSummary) {
        this.props.resetStatusSummary();
        this.props.getSummary();
      }
    }, 12000);
    setInterval(() => {
      if(this.props.fullData.statusCodePrice) {
        this.props.resetStatusPrice();
        this.props.getPrice();
      }
    }, 60000);
  }
  render(){
    return(
      <div className={this.props.home ? "summary-div" : "summary-div hide-mobile"}>
        <div className="summary shadow">
          <div className="row">
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-box"></i>
                </div>
                <div className="title">
                  <p>Latest Block</p>
                </div>
                <div className="title-value">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p>{parseInt(this.props.fullData.latestBlock[0].blockHeight).toLocaleString()}</p>
                  : <p>0</p>}
                </div>
                <hr/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-list-nested"></i>
                </div>
                <div className="title">
                  <p>Transaction(s)</p>
                </div>
                <div className="title-value">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p className="summary-content">{this.props.fullData.latestBlock[0].numTx}</p>
                  : <p className="summary-content">0</p>}
                </div>
                <hr/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-person-check"></i>
                </div>
                <div className="title">
                  <p>Active Validators</p>
                </div>
                <div className="title-value">
                  <p>{(this.props.fullData.activeValidators).length}/{this.props.fullData.maxValidator}</p>
                </div>
                <hr/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div">
                <div className="title-icon">
                  <i className="bi bi-lightning-charge"></i>
                </div>
                <div className="title">
                  <p>Avg. Block Speed</p>
                </div>
                <div className="title-value">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p>{parseFloat(((this.props.fullData.latestBlock[0].blockTime-this.props.fullData.blockFirst)/this.props.fullData.latestBlock[0].blockHeight)/1000).toFixed(3)}s</p>
                    : <p>0</p>}
                </div>
                <hr/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-diamond"></i>
                </div>
                <div className="title">
                  <p>Total Supply</p>
                </div>
                <div className="title-value">
                  <p>
                    {
                      parseInt((this.props.fullData.totalSupply)/10**6) > 10**9 ?
                        `${parseInt((this.props.fullData.totalSupply)/10**6/10**9).toLocaleString()}Billion`
                      : parseInt((this.props.fullData.totalSupply)/10**6) > 10**6 ?
                        `${parseInt((this.props.fullData.totalSupply)/10**6/10**6)}Million`
                      : parseInt((this.props.fullData.totalSupply)/10**6)
                    }
                  </p>
                </div>
                <hr className="hr-mobile"/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-info-lg"></i>
                </div>
                <div className="title">
                  <p>Bonded Coins</p>
                </div>
                <div className="title-value">
                  <p>
                    {
                      parseInt((this.props.fullData.bondedCoins)/10**6) > 10**9 ?
                        `${parseInt((this.props.fullData.bondedCoins)/10**6/10**9).toLocaleString()}Billion`
                      : parseInt((this.props.fullData.bondedCoins)/10**6) > 10**6 ?
                        `${parseInt((this.props.fullData.bondedCoins)/10**6/10**6)}Million`
                      : parseInt((this.props.fullData.bondedCoins)/10**6)
                    }
                  </p>
                </div>
                <hr className="hr-mobile"/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-graph-up"></i>
                </div>
                <div className="title">
                  <p>Price</p>
                </div>
                <div className="title-value">
                  <p>${parseFloat(this.props.fullData.price).toLocaleString(undefined, {maximumFractionDigits: 4})}</p>
                </div>
                <hr className="hr-mobile"/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="title-div">
                <div className="title-icon">
                  <i className="bi bi-currency-exchange"></i>
                </div>
                <div className="title">
                  <p>Volume</p>
                </div>
                <div className="title-value">
                  <p>${parseFloat(this.props.fullData.volume).toLocaleString(undefined, {maximumFractionDigits: 4})}</p>
                </div>
              </div>
            </div>
            {
              this.props.home ?
              <> 
                <div className="col-6">
                  <div style={{width: '130px', height: '130px', margin: "0 auto", padding: "10px"}}>
                    <CircularProgressbarWithChildren
                      value={parseInt(this.props.fullData.bondedCoins)/parseInt(this.props.fullData.totalSupply)*100}
                      styles={buildStyles({
                        pathColor: "#5755b9",
                        trailColor: "#fff"
                      })}
                    >
                      <p style={{fontSize: "11px", marginBottom: "0px"}}>Bonded Coins</p>
                      <strong style={{fontSize: "15px"}}>
                      {
                        this.props.fullData.bondedCoins > 0 && this.props.fullData.totalSupply > 0 ?
                          (parseInt(this.props.fullData.bondedCoins)/parseInt(this.props.fullData.totalSupply)*100).toFixed(2)
                        : 100
                      }%</strong>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                <div className="col-6">
                  <div style={{width: '130px', height: '130px', margin: "0 auto", padding: "10px"}}>
                    <CircularProgressbarWithChildren
                      value={this.props.fullData.inflation}
                      styles={buildStyles({
                        pathColor: "#5755b9",
                        trailColor: "#fff"
                      })}
                    >
                      <p style={{fontSize: "11px", marginBottom: "0px"}}>Inflation</p>
                      <strong style={{fontSize: "15px"}}>{parseInt(this.props.fullData.inflation).toFixed(2)}%</strong>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getBlocks,
  getValidators,
  getSummary,
  getTransactions,
  getPrice,
  resetStatusSummary,
  resetStatusBlock,
  resetStatusTransaction,
  resetStatusValidator,
  resetStatusPrice
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);